import React from 'react';
import { HomepageBanner, HomepageCallout } from 'gatsby-theme-carbon';
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import HomepageVideo from '../../components/HomepageVideo';
import Carbon from '../../images/gracanica-front.jpg';

// Component to be shadowed

const FirstLeftText = () => (
  <p>
    Razmisli → <em>Djeluj</em>
  </p>
);

const FirstRightText = () => (
  <p>
    <strong>Izgradite veze</strong>
    <br />
    Vrata Bosanskog Kulturnog Centra Gračanica su otvorena za svakoga.
    Kontaktirajte nas, ili posjetite, doprinesite nasljeđu i vlastitom <em>iskustvu{' '}.</em>
  </p>
);

const BannerText = () => <h1>Gračanička Kultura</h1>;

const customProps = {
  FirstCallout: (
  <HomepageBanner renderText={BannerText} image={Carbon} />
  ),
  Banner: (
    <HomepageVideo
      src="videos/bkc-video1.mp4"
      poster="images/ogimage.png"
    />
  ),
  SecondCallout: (
  <HomepageCallout
  leftText={FirstLeftText}
  rightText={FirstRightText}
  color="#fcffff"
  backgroundColor="#ac0000"
  />
  ),
};

// spreading the original props gives us props.children (mdx content)
function ShadowedHomepage(props) {
  return <HomepageTemplate {...props} {...customProps} />;
}

export default ShadowedHomepage;
