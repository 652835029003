import React from 'react';
import Switcher from 'gatsby-theme-carbon/src/components/Switcher';

const links = [
  { href: 'https://bkc-gracanica.ba/', linkText: 'BKC Gračanica' },
  {
    href: 'https://radiogracanica.ba/',
    linkText: 'Radio Gračanica',
  },
  {
    href: 'https://kultura.ba/organizacije/bosanski-kulturni-centar-gracanica',
    linkText: 'Kultura .ba',
  },
  {
    href: 'https://gracanica.gov.ba/kultura-22/',
    linkText: 'Gračanica gov.ba',
  },
];

const CustomSwitcher = () => <Switcher links={links} />;

export default CustomSwitcher;
