import React from 'react';
import Header from 'gatsby-theme-carbon/src/components/Header';

const CustomHeader = props => (
  <Header {...props}>
    Bosanski kulturni centar&nbsp;<span> BKC</span> <img src="images/icon24.png" title="Logo" alt="Logo" />Gračanica
  </Header>
);

export default CustomHeader;
