import React from 'react';
import ResourceLinks from 'gatsby-theme-carbon/src/components/LeftNav/ResourceLinks';

const links = [
  {
    title: 'Baci lajk',
    actionIcon: 'download',
    href: 'https://www.facebook.com/BKC-Gracanica-110689465667400/',
  },
  {
    title: 'Slušaj Radio',
    actionIcon: 'article',
    href: 'https://radiogracanica.ba',
  }
];

// shouldOpenNewTabs: true if outbound links should open in a new tab
const CustomResources = () => <ResourceLinks shouldOpenNewTabs links={links} />;

export default CustomResources;
