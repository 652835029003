// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biblioteka-index-mdx": () => import("./../../../src/pages/biblioteka/index.mdx" /* webpackChunkName: "component---src-pages-biblioteka-index-mdx" */),
  "component---src-pages-bkc-index-mdx": () => import("./../../../src/pages/bkc/index.mdx" /* webpackChunkName: "component---src-pages-bkc-index-mdx" */),
  "component---src-pages-galerija-index-mdx": () => import("./../../../src/pages/galerija/index.mdx" /* webpackChunkName: "component---src-pages-galerija-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-kino-index-mdx": () => import("./../../../src/pages/kino/index.mdx" /* webpackChunkName: "component---src-pages-kino-index-mdx" */),
  "component---src-pages-kontakt-index-mdx": () => import("./../../../src/pages/kontakt/index.mdx" /* webpackChunkName: "component---src-pages-kontakt-index-mdx" */),
  "component---src-pages-privatnost-index-mdx": () => import("./../../../src/pages/privatnost/index.mdx" /* webpackChunkName: "component---src-pages-privatnost-index-mdx" */),
  "component---src-pages-rg-index-mdx": () => import("./../../../src/pages/rg/index.mdx" /* webpackChunkName: "component---src-pages-rg-index-mdx" */),
  "component---src-pages-zmz-index-mdx": () => import("./../../../src/pages/zmz/index.mdx" /* webpackChunkName: "component---src-pages-zmz-index-mdx" */)
}

