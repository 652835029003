import React from 'react';
import Footer from 'gatsby-theme-carbon/src/components/Footer';

const Content = ({ buildTime }) => (
  <>
    <p>
      <code>Sigurnost</code> aplikacija je zadnji put ažurirana i uspješno pokrenuta:
      <br />
      {buildTime}
      <br />
      <a href="/privatnost">
        Privatnost & Uvjeti
      </a>
    </p>
  </>
);

const links = {
  firstCol: [
    { href: 'https://bkc-gracanica.ba',
      linkText: 'BKC Gračanica'
    },
    { href: 'https://radiogracanica.ba',
      linkText: 'Radio Gračanica',
    },
      { href: 'https://monkeyshub.space',
        linkText: 'Dizajn & Revolucija',
      },
  ],
  secondCol: [
    {
      href: 'https://www.facebook.com/BKC-Gracanica-110689465667400/',
      linkText: '@facebook.com',
    },
    {
      href: 'https://kultura.ba/organizacije/bosanski-kulturni-centar-gracanica',
      linkText: '@kultura.ba',
    },
    {
      href: 'https://gracanica.gov.ba/kultura-22/',
      linkText: '@gracanica.gov.ba',
    },
  ],
};

const CustomFooter = () => <Footer links={links} Content={Content} />;

export default CustomFooter;
